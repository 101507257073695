<template>
  <div class="accordion" :class="{skeleton: !state.loaded}" :data-constraint-flag="state.category.constraintFlag">
    <div class="inner">
      <div class="header">
        <div class="title">
          <span>{{ state.category.title }}</span>
        </div>
        <div class="desc">
          <span>아래 목록 중 참여를 원하시는 항목을 선택해주세요.</span>
          <div v-if="state.category.managerTel">
            <span>담당자에게 바로 문의하기 : </span>
            <a :href="`tel:${state.category.managerTel}`">{{ $lib.getPhoneNumberFormat(state.category.managerTel, true) }}</a>
          </div>
        </div>
      </div>
      <div :id="`${component.name}Accordion`" class="items">
        <div class="alert alert-warning font-sm" v-if="state.category.constraintFlag === 'N'">이 페이지는 제약 조건이 없으므로 유효하지 않습니다.</div>
        <div class="item" v-for="(f, idx1) in state.forms" :key="idx1" :class="{skeleton: !state.constraint.loaded}">
          <div class="parent" :id="`${component.name}Parent${idx1}`" :data-id="f.id" :data-name="f.name">
            <button class="btn btn-block collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" :data-target="`#${component.name}Child${idx1}`" aria-expanded="true"
                    :aria-controls="`${component.name}Parent${idx1}`" @click="setConstraints($event, f.id, f.name)">
              <div class="d-flex align-items-end left">
                <span class="ico" :style="{backgroundImage: `url('/assets/ico/page.accordion.${getRegion(f.title)}.symbol.svg')`}"></span>
                <b>{{ f.title }}</b>
              </div>
              <i class="fa fa-angle-up"></i>
            </button>
          </div>
          <div :id="`${component.name}Child${idx1}`" class="collapse child" :aria-labelledby="`${component.name}Parent${idx1}`" :data-parent="`#${component.name}Accordion`">
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <td></td>
                  <th v-for="(h, idx2) in state.constraint.row.head" :key="idx2">
                    <span class="parent-label">{{ h.value }}</span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(b, idx3) in state.constraint.row.body" :key="idx3">
                  <td>
                    <span class="font-xs">{{ b.key }}</span>
                  </td>
                  <td v-for="(i, idx4) in state.constraint.row.head" :key="idx4">
                    <button class="btn btn-light" v-if="b.value.skeleton">Hold on</button>
                    <span v-else-if="!b.value[i.key]"></span>
                    <button class="btn btn-light" disabled v-else-if="b.value[i.key].max && b.value[i.key].count >= b.value[i.key].max">
                      <span>신청</span>
                      <span class="d-none d-sm-inline">&nbsp;</span>
                      <span>마감</span>
                    </button>
                    <Anchor class="btn btn-light" :href="b.value[i.key].url" v-else>
                      <span>신청</span>
                      <span class="d-none d-sm-inline">&nbsp;</span>
                      <span v-if="b.value[i.key].max" class="d-block">({{ b.value[i.key].count || 0 }}/{{ b.value[i.key].max }})</span>
                      <span v-else class="d-block">(제한 없음)</span>
                    </Anchor>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {defineComponent, reactive} from "@vue/composition-api";
import ApplicationItem from "@/pages/archive/ApplicationItem";
import router from "@/scripts/router";
import http from "@/scripts/http";
import Anchor from "@/components/Anchor";

function Component(initialize) {
  this.name = "pageArchiveApplication";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor, ApplicationItem},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      state.loaded = false;

      http.get(`/api/archive/categories/${categoryId}`).then(({data}) => {
        state.category = data.body;
      });

      for (let i = 0; i < 5; i += 1) {
        state.forms.push({
          title: "Wait a moment"
        });
      }

      http.get(`/api/archive/categories/${categoryId}/archives`).then(({data}) => {
        state.loaded = true;
        state.forms = data.body;
      });
    });

    const state = reactive({
      loaded: false,
      category: {
        constraintChildLabel: "",
        constraintFlag: "",
        constraintParentLabel: "",
        delFlag: "N",
        id: 0,
        ord: 0,
        title: ""
      },
      forms: [],
      constraint: {
        loaded: false,
        row: {
          head: [],
          body: []
        },
      },
    });

    const categoryId = router.app.$route.params.id;

    const getUrl = (name) => {
      const urlPrefix = `${location.origin}/archives/`;

      return urlPrefix + name;
    };

    const setConstraints = async (e, archiveId, name) => {
      if (state.category.constraintFlag !== "Y") {
        return;
      }

      if (!e.target.closest(".collapsed")) {
        return;
      }

      state.constraint.row.head = [];
      state.constraint.row.body = [];
      state.constraint.loaded = false;

      for (let i = 0; i < 4; i += 1) {
        state.constraint.row.head.push({
          key: "Wait" + i,
          value: "Wait",
        });

        state.constraint.row.body.push({
          key: "Wait" + i,
          value: {
            skeleton: true
          }
        });
      }

      const res1 = await http.get(`/api/archive/categories/${categoryId}/archives/${archiveId}/status`);

      http.get(`/api/archive/categories/${categoryId}/archives/${archiveId}/constraints`).then(({data}) => {
        state.constraint.row.head = [];
        state.constraint.row.body = [];
        state.constraint.loaded = true;
        const constraints = data.body.filter(c => !c.parentId);

        if (!constraints.length) {
          return;
        }

        for (let i in constraints) {
          const parent = constraints[i];
          parent.children = [];

          data.body.filter(c1 => c1.parentId === parent.id).forEach((c2) => {
            c2.url = `${getUrl(name)}?constraintId=${c2.id}`;
            parent.children.push(c2);
          });
        }

        for (let i in constraints) {
          state.constraint.row.head.push({
            key: constraints[i].id,
            value: constraints[i].title
          });

          for (let j in constraints[i].children) {
            let row;
            const child = constraints[i].children[j];
            const key = child.title;

            for (let k in state.constraint.row.body) {
              if (state.constraint.row.body[k].key === key) {
                row = state.constraint.row.body[k];
                break;
              }
            }

            if (!row) {
              row = {key, value: {}};
              state.constraint.row.body.push(row);
            }

            row.value[constraints[i].id] = {
              count: res1.data.body.find(c => c.constraintId === child.id)?.count,
              url: child.url,
              max: child.max
            };
          }
        }
      });
    };

    const getRegion = (title) => {
      if (title.includes("인천")) {
        return "incheon";
      } else if (title.includes("경기")) {
        return "gyeonggi";
      } else if (title.includes("서울")) {
        return "seoul";
      } else if (title.includes("충북")) {
        return "chungbuk";
      } else if (title.includes("경북")) {
        return "gyeongbuk";
      } else if (title.includes("김해")) {
        return "gimhae";
      } else if (title.includes("울산")) {
        return "ulsan";
      } else if (title.includes("전북")) {
        return "jeonbuk";
      } else if (title.includes("광주")) {
        return "gwangju";
      } else if (title.includes("전남")) {
        return "jeonnam";
      } else if (title.includes("세종")) {
        return "sejong";
      } else if (title.includes("대전")) {
        return "daejeon";
      } else if (title.includes("대구")) {
        return "daegu";
      } else if (title.includes("부산")) {
        return "busan";
      } else if (title.includes("제주")) {
        return "jeju";
      }
    };

    return {component, state, getRegion, setConstraints,};
  }
})
;
</script>

<style lang="scss" scoped>
.accordion {
  padding: $px50 $px15;
  background: #fff;

  .inner {
    border-radius: $px4;

    > .header {
      padding: 0 $px20;

      .title {
        font-size: $px19;
        font-weight: 900;
      }

      .desc {
        color: #666;
        font-size: $px14;
        padding-top: $px7;

        > span {
          display: block;
        }
      }
    }

    .items {
      padding-top: $px50;

      .alert {
        margin-bottom: $px15;
        padding: $px20 $px25;
      }

      .item {
        border-radius: $px4;
        border: $px1 solid #eee;

        .parent {
          background: #fff;
          padding: 0;

          .btn {
            text-align: left;
            font-size: $px14;
            padding: $px20;
            transition: 0.18s;

            .left {
              gap: $px12;

              .ico {
                display: inline-block;
                width: $px26;
                height: $px26;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100%;
              }

              .region {
                font-weight: 500;
              }
            }

            &.collapsed {
              i {
                transform: rotateX(180deg);
              }

              &:hover {
                background-color: $colorBackground;
              }
            }
          }
        }

        .child {
          background: #fff;
          border-radius: $px4;
          padding: 0 $px10;

          .table-responsive .table {
            table-layout: fixed;
            margin-bottom: $px11;

            tr {
              font-size: $px14;
              text-align: center;

              td, th {
                border: 0;
                vertical-align: middle;
                padding-left: $px7;
                padding-right: $px7;

                .desc {
                  font-size: $px10;
                }

                .btn {
                  border: 1px solid #f7f7f7;
                  font-size: $px12;
                  padding: $px6 $px12;

                  > span {
                    word-break: keep-all;
                  }
                }

                .parent-label {
                  word-break: keep-all;
                }
              }

              th {
                padding-top: $px12;
                font-size: $px13;
              }
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: $px12;
        }

        &.skeleton {
          .table-responsive .table tr {
            td, th {
              span, b, a, .btn {
                @include skeleton;
              }
            }
          }
        }
      }
    }
  }

  &[data-constraint-flag=N] {
    .inner .items .item {
      .parent .btn {
        cursor: default;

        i {
          display: none;
        }

        &:hover {
          background: inherit;
        }
      }

      .child {
        display: none;
      }
    }
  }

  @media(max-width: 991px) {
    .inner {
      .items .item .child .table-responsive .table tr {
        th, td {
          padding-left: $px5;
          padding-right: $px5;
        }

        td .btn span {
          display: block;
        }
      }
    }
  }

  &.skeleton {
    .inner {
      > .header span {
        @include skeleton;
      }

      .items .item {
        @include skeleton;

        .parent .btn {
          @include skeleton;

          .ico {
            display: none;
          }
        }
      }
    }
  }
}
</style>